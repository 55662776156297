import { defineStore } from 'pinia'

export const appStore = defineStore('appStore', {
    state: () => ({
        pageKey:"image-resize",
		loading:true,
		color:"blue",
		theme:"light" as "light"|"dark",
		waiguan:"cupertino",
		maxZhi:"1.5"
    }),
    getters: {

    },
    actions: {
		toogleDark(theme:"dark"|"light"){
			if(!theme&&localStorage.getItem("dark")){
				theme = <"dark"|"light">localStorage.getItem("dark")||"dark"
			}else if(!theme&&!localStorage.getItem("dark")){
				const isDark = window.matchMedia("(prefers-color-scheme: dark)");
				if(isDark){
					theme = "dark"
				}else{
					theme = "light"
				}
			}
			this.theme = theme
			localStorage.setItem("dark",theme)
			const dv = document.head.getElementsByTagName("meta");
			for(let i=0;i<dv.length;i++){
				if(dv.item(i)?.getAttribute("name")=="xel-theme"){
					if(theme=="light"){
						dv.item(i)?.setAttribute("content","plugs/xel/themes/"+this.waiguan+".css")
					}else{
						dv.item(i)?.setAttribute("content","plugs/xel/themes/"+this.waiguan+"-dark.css")
					}
					break;
				}
			}
		},
		toogleColor(theme:string){
			if(!theme&&localStorage.getItem("color")){
				theme = localStorage.getItem("color")||"green";
			}
			if(!theme){
				theme = "green"
			}
			this.color = theme;
			localStorage.setItem("color",theme)
			const dv = document.head.getElementsByTagName("meta");
			for(let i=0;i<dv.length;i++){
				if(dv.item(i)?.getAttribute("name")=="xel-accent-color"){
					dv.item(i)?.setAttribute("content",theme)
					break;
				}
			}
		},
		toogleWG(theme:string){
			if(!theme){
				theme = "fluent"
			}
			if(!theme&&localStorage.getItem("waiguan")){
				theme = localStorage.getItem("waiguan")||"fluent";
			}
			this.waiguan = theme;
			localStorage.setItem("waiguan",theme)
			this.toogleDark(this.theme)
		},
		getWaiguan(){
			var theme = "fluent"
			if(localStorage.getItem("waiguan")){
				theme = localStorage.getItem("waiguan")||"fluent";
			}
			this.waiguan = theme;
			localStorage.setItem("waiguan",theme)
		},
		settingMaxzhi(zhi:string){
			if(localStorage.getItem("waiguan")&&!zhi){
				zhi = localStorage.getItem("zhi")||"1.5";
			}
			if(!zhi){
				zhi = "1.5"
			}
			
			this.maxZhi = zhi;
			localStorage.setItem("zhi",zhi)
		},
		
    },
})