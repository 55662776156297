<template>
  <x-progressbar small="small" v-if="!showbody"></x-progressbar>

  <div v-show="showbody">
    <HeadMenu></HeadMenu>
    <keep-alive>
		<PageImageResize v-if="store.pageKey=='image-resize'"></PageImageResize>
	</keep-alive>
	<keep-alive>
		<Appsettings v-if="store.pageKey=='settings'"></Appsettings>
	</keep-alive>
	<About v-if="store.pageKey=='about'"></About>
	<keep-alive>
		<PageListSet v-if="store.pageKey=='all-zh'"></PageListSet>
	</keep-alive>
  </div>
</template>
<script setup lange="ts">
import {ref} from "vue"
import HeadMenu from "@/components/HeadMenu.vue";
import PageImageResize from '@/components/PageImageResize';
import Appsettings from '@/components/Appsettings';
import About from '@/components/About';
import PageListSet from '@/components/PageListSet';
// import init from '../public/pkg/tmui_xz.js';
import {appStore} from '@/store/appStore';
// import init, { tmui_img_resize, tmui_img_compress, tmui_img_blur } from './../pkg/tmui_xz.js';
// import { getFileWatch } from './js/imagesDom.js';
const showbody = ref(false);
const store = appStore()
store.settingMaxzhi();
store.getWaiguan();
store.toogleDark();
store.toogleColor();
async function run() {
  // await init();
  setTimeout(()=>{
    showbody.value = true;
	store.loading = false;
	
  },1000)
}

run();
</script>
<style>
html,body{
  overflow: hidden;
  user-select:none;
/*  background-image: url(assets/black-orchid.png) !important;
  background-color: var(--background-color);
  background-repeat: repeat; */
}

.tmicon {
	color: var(--text-color);
	font-size: 18px;
	font-weight: 100;
}
</style>
