<template>
	<div class="box">

		<x-card style="width: 96%;max-width: 750px;padding-bottom: 15px;">
		<div style="text-align: center;padding-top: 24px;">
			<img  style="width: 160px;" src="../assets/logo.svg" />
		</div>
			<x-label style="flex-shrink: 1;margin: 0 15px;font-size: 2rem;font-weight: bold;text-align: center;margin-top: 24px;margin-bottom: 10px;">TMUI IMAGE BOX</x-label>
			<x-label style="flex-shrink: 1;margin: 0 15px;font-size: 14px;font-weight: bold;text-align: center;">在线压缩，转换图片批处理工具</x-label>
			<hr style="margin: 24px 0;">
			<div style="">
				<x-label style="flex-shrink: 1;margin: 0 15px;">1、主要是针对图片的格式、压缩、大小调整批量处理、base64转换等</x-label>
				<x-label style="flex-shrink: 1;margin: 0 15px;">2、可以收藏本工具，临时处理图片用，不用使用专业工具就好了。</x-label>
				<x-label style="flex-shrink: 1;margin: 0 15px;">3、本程序是使用Rust编写，性能高，保护隐私，所有处理任何文件都不用上传到服务器，而是使用本机处理。所以隐私方面绝对的放心。</x-label>
				<x-label style="flex-shrink: 1;margin: 0 15px;">4、本应用完全免费可商用，因为不占我任何服务器资源，所以你想怎么用就怎么用。</x-label>
				<x-label style="flex-shrink: 1;margin: 0 15px;">5、你还可以把本页面嵌入到你的后台终端让客户在线处理图片，让不懂的客户不用去下载什么 图片软件就可以在线快速处理。比如客户上传的产品图片等等 需要批量在线处理的场景。</x-label>
				<x-label style="flex-shrink: 1;margin: 0 15px;">6、商用时请保留版权链接。</x-label>
			</div>
			<hr style="margin: 24px 0;">
			<x-label style="flex-shrink: 1;margin: 0 15px;text-align: center;">©TMUI <a href="https://tmui.design" target="_blank">https://tmui.design</a> | 2023</x-label>
			<x-label style="flex-shrink: 1;margin: 0 15px;text-align: center;"><a href="mailto:zhongjihan@sina.com">点击联系我</a></x-label>
		</x-card>

	</div>
</template>
<script setup lang="ts">

</script>
<style scoped>
	.box {
		display: flex;
		flex-flow: row;
		justify-content: center;
	}
</style>